//
// Footer
//


// General styles

.footer {
    background: $footer-bg;
    padding: $footer-padding-y $footer-padding-x;
    border-bottom: 1px solid transparent;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	height: 35px;
	left: 0;
	position: fixed;
	right: 0;
	bottom: 0;
	padding: 6px;
	z-index: 0 !important;

    .col-footer {
        .heading {
            color: $footer-heading-color;
            letter-spacing: 0;
            font-size: $footer-heading-font-size;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-bottom: 1rem;
        }
    }

    .nav .nav-item .nav-link,
    .footer-link {
        color: $footer-link-color !important;

        &:hover {
            color: $footer-link-hover-color !important;
        }
    }

    .list-unstyled li a {
        display: inline-block;
        padding: .125rem 0;
        color: $footer-link-color;
        font-size: $footer-link-font-size;

        &:hover {
            color: $footer-link-hover-color;
        }
    }

    .copyright {
        font-size: 14px;
        // font-size: $font-size-sm;
    }
    // background: $primary;
	/* background: -moz-linear-gradient(left, $primary 0%, #453a94 100%);
	background: -webkit-linear-gradient(left, $primary 0%, #453a94 100%);
	background: -ms-linear-gradient(left, $primary 0%, #453a94 100%);
	background: linear-gradient(to right, $primary 0%, #453a94 100%); */
	// border-bottom: 1px solid transparent;
	// box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	// height: 35px;
	// left: 0;
	// position: fixed;
	// right: 0;
	// bottom: 0;
	// padding: 4px;
	// z-index: 10 !important;
}


// Dark footer

.footer-dark {
    .col-footer .heading {
        color: $white;
    }
}


// Footer nav used for copyright and some links, but not limited to this

.nav-footer {
    .nav-link {
        font-size: $font-size-sm;
    }

    .nav-item:last-child {
        .nav-link {
            padding-right: 0;
        }
    }
}

.styled-table-variant {
    border-collapse: collapse;
    width: 100%;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    thead tr {
        background-color: $primary;
        color: #ffffff;
        text-align: left;
    }

    th, td {
        padding: 12px 15px;
    }

    tbody tr {
        border-bottom: 1px solid #dddddd;
    }

    tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }

    tbody tr:last-of-type {
        border-bottom: 2px solid $primary;
    }

    tbody tr.active-row {
        font-weight: bold;
        color: $primary;
    }
}


// Footer with cards over

.footer.has-cards {
    overflow: hidden;
    padding-top: 500px;
    margin-top: -420px;
    position: relative;
    background: transparent;
    pointer-events: none;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 600px;
        height: 2000px;
        background: theme-color("secondary");
        transform: skew(0,-8deg);
    }

    .container {
        pointer-events: auto;
        position: relative;
    }
}
