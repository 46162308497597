//
// Custom Style
//

.bg-gradient-bikers {
  background: linear-gradient(87deg, #FF502A 0, #DF2700 100%) !important; 
}
.box-card-custom {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn-filter-bigger {
	// background-color: #fff;
    // border: 1px solid #fff;
		font-weight: 500;
		margin-top: 0 !important;
		padding-top: 0 !important;
		font-size: 16px;
    color: #FF502A;
}