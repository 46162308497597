.ant-table {
  font-size: 0.9375rem !important;
  color: rgb(51, 51, 51) !important;
}
.ant-table-body {
  overflow-x: auto !important;
}
.ant-table-thead > tr > th {
  background-color: rgba(255, 255, 255, 0.85) !important;
  border-bottom: 2px solid #dee2e6 !important;
  font-weight: 700;
  font-family: "Segoe UI" !important;
  /* text-align: left!important; */
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td {
  border-right: 0px solid #f0f0f0 !important;
  border-top: 1px solid #dee2e6 !important;
}
/* .ant-pagination-options-size-changer.ant-select {
    display: none !important;
} */
td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0) !important;
}
.ant-table.ant-table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.ant-table.ant-table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.ant-table.ant-table td h2 a {
  color: #333;
}
.ant-table.ant-table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}
.ant-table-thead > tr,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 8px !important;
}
.ant-table.ant-table tr {
  box-shadow: 0 0 3px #e5e5e5;
}
.ant-pagination {
  margin-top: 20px !important;
  text-align: end;
}
.ant-pagination-prev {
  border: 1px solid #dee2e6;
  padding: 1px 10px;
  background-color: #fff;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.ant-pagination-next {
  border: 1px solid #dee2e6;
  padding: 1px 10px;
  background-color: #fff;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: rgb(108, 117, 125) !important;
  font-family: "CircularStd", sans-serif !important;
  font-size: 15px !important;
  height: 36px !important;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 0px !important;
}
.ant-pagination-item {
  margin-right: 0px !important;
  padding: 1px 0px !important;
  height: 36px !important;
}

.ant-pagination-total-text {
  float: left;
  color: rgb(51, 51, 51) !important;
  font-family: "CircularStd", sans-serif !important;
  font-size: 15px !important;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: rgb(0, 197, 251) !important;
  border-color: rgb(0, 197, 251) !important;
  color: rgb(255, 255, 255) !important;
  z-index: 1 !important;
}

.ant-table-pagination-right {
  float: none !important;
}
.text-right {
  text-align: right;
}

.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}

.demo-editor {
  min-height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.rdw-editor-wrapper .custom-tool {
  margin-bottom: 6px;
  cursor: pointer;
}
